// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Bootstrap Select
@import '~bootstrap-select/sass/bootstrap-select.scss';

// Telephone prefix
@import '~intl-tel-input/src/css/intlTelInput.scss';

// Bootstrap Calender
@import '~bootstrap-datepicker/dist/css/bootstrap-datepicker3.standalone.css';

//Slick Slider
@import '~slick-slider/slick/slick.scss';
@import '~slick-slider/slick/slick-theme.scss';

//Jquery fancybox
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min.css';

//JQuery Confirm
@import '~jquery-confirm/dist/jquery-confirm.min.css';


//font awesome
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/brands.scss';
@import '~@fortawesome/fontawesome-free/scss/regular.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
@import '~@fortawesome/fontawesome-free/scss/v4-shims.scss';

body {
    font-weight: 400;
    #mobileMenu {
        transform: translateX(-100%);
        transition: 0.5s cubic-bezier(0.26, 0.74, 0.22, 0.99);
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: $white;
        z-index: 99;
        opacity: 0;
    }

    &.menu-open {
        overflow: hidden;

        #mobileMenu {
            transform: translateX(0);
            transition: 0.5s cubic-bezier(0.26, 0.74, 0.22, 0.99);
            opacity: 1;
        }

        #mobileHead {
            position: relative;
            z-index: 98;
            background-color: $white;
            border-bottom: 1px solid $gray-200;

            .block {
                margin: 0;
                padding: 0;
            }
        }

        #mobileContainer {
            position: fixed;
            top: 90px;
            bottom: 0;
            left: 0;
            right: 0;
            overflow: scroll;
            z-index: 97;
            height: calc(100% - 90px);
        }

        .toggle-menu {
            svg {
                top: 0px;
            }
        }
    }
}

a {
    transition: 0.2s;

    &.pdf-icon {
        color: $danger;
        font-size: 30px;
        margin: 5px;
        text-decoration: none;

        &:hover {
            color: darken($danger, $amount: 20);
        }
    }
}

h1,
h2,
h3,
.btn {
    font-family: $font-family-sans-serif;
}

strong {
    font-weight: 800;
}

img {
    width: 100%;
}

.iti {
    width: 100%;
}

.vh-center {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.slider {
    margin-left: -15px;
    margin-right: -15px;
    .slider-item {
        position: relative;
        float: left;
    }

    .slick-slide {
        .slide-description {
            position: absolute;
            top: 40%;
            z-index: 1;
            width: 450px;
            background-color: rgba(0, 0, 0, 0.7);
            padding: 30px;
            color: $white;

            @include media-breakpoint-down(sm) {
                width: 70%;
                padding: 10px;
                top: 10%;
            }

            h3 {
                font-size: 30px;
                margin-bottom: 0px;

                @include media-breakpoint-down(sm) {
                    font-size: 17px;
                }
            }

            p {
                font-size: 13px;
                font-weight: 400;
                line-height: 18px;
                @include media-breakpoint-down(sm) {
                    font-size: 10px;
                    line-height: 14px;
                }

                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }

        &:nth-child(odd) {
            .slide-description {
                left: 45px;
                border-left: 5px solid $primary;

                @include media-breakpoint-down(sm) {
                    left: 10px;
                }
            }
        }

        &:nth-child(even) {
            .slide-description {
                right: 45px;
                border-right: 5px solid $primary;
                text-align: right;

                @include media-breakpoint-down(sm) {
                    right: 10px;
                }
            }
        }
    }
}

.gray-bg {
    background-color: $gray-200;
}

.section-padding {
    padding-top: 15px;
    padding-bottom: 15px;
}

.slick-slide {
    display: none;

    img {
        width: 100%;
    }
}

.slick-prev,
.slick-next {
    display: none !important;

    &:before {
        color: $black;
    }

    @include media-breakpoint-down(sm) {
        display: none !important;
    }
}

.alert{
    &.hour-description{
        padding: 10px;
        font-size: 10px;
        p{
            margin-bottom: 0px;
        }
    }
}

.gallery-items {
    margin: 0 -5px;
    padding: 0;
    list-style: none;

    .gallery-item {
        float: left;
        width: 25%;

        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        a {
            display: block;
            position: relative;
            padding: 5px;
            overflow: hidden;

            img {
                max-width: 100%;
            }

            .gallery-inside {
                position: absolute;
                top: 5px;
                bottom: 5px;
                left: 5px;
                right: 5px;
                display: flex;
                align-items: center;
                transition: 0.2s;
                background-color: rgba(0, 0, 0, 0.5);
                opacity: 0;

                .inside-content {
                    display: table;
                    margin: 0 auto;
                    color: $white;
                    padding: 10px;

                    h3 {
                        font-weight: 400;
                        font-size: 16px;
                    }
                }
            }

            &:hover {
                .gallery-inside {
                    opacity: 1;
                    transition: 0.2s;
                }
            }
        }
    }

}

.gallery-filter {
    padding-bottom: 10px;
    margin: 0 -3px;

    .btn {
        background-color: $gray-300;
        display: inline-block;
        margin: 3px;
        box-shadow: none;
        font-weight: 800;
        border-radius: 0;

        &.btn-primary {
            background-color: darken($primary, $amount: 20%);
            border-color: darken($primary, $amount: 20%);
        }
    }
}

.image-block{
    max-height: 175px;
    overflow: hidden;
}
.image-folder{
    max-height: 245px;
    min-height: 245px;
    overflow: hidden;
}

.block {
    margin: 15px;
    padding: 15px;

    @include media-breakpoint-down(sm) {
        margin: 5px;
        padding: 5px;
    }

    &.system-message {
        button {
            padding: 0px;
        }
    }

    &.offline,
    &.site-form {
        margin: 0 auto;
        padding: 30px;
        border-radius: 5px;
        background-color: $white;
        // border: 1px solid $gray-200;
        // box-shadow: 0 0 5px rgb(0 0 0 / 20%);
        border: 1px solid $gray-200;
        

        &.large-size {
            max-width: 600px;
        }
        &.small-size {
            max-width: 400px;
        }

        @include media-breakpoint-down(sm) {
            padding: 15px;
        }

        legend {
            padding-bottom: 20px;
            font-size: 20px;
            text-align: center;
        }

        .form-control,
        .custom-select {
            min-height: 50px;
            font-size: 17px;
            padding-left: 20px;
            padding-right: 20px;

            &:focus {
                box-shadow: none;
                border-color: $primary;
            }
        }

        .iti--separate-dial-code {
            .iti__selected-flag {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
        }

        .input-group {

            .form-control,
            .custom-select {
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
            }

            .iti--allow-dropdown {

                .form-control,
                .custom-select {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }
            }
        }


        .input-group-text {
            padding: 0.375rem 1.1rem 0.375rem 1.2rem;

            .fa {
                font-size: 25px;
            }
        }

        .bootstrap-select {
            width: 100% !important;

            .dropdown-toggle {
                font-size: 17px;
                line-height: 36px;
                border-radius: 4px;
                background: none;
                font-weight: 300 !important;
                box-shadow: none;
                border: 1px solid #ced4da;
                padding: 0.375rem 1.1rem 0.375rem 1.2rem;
            }

            .dropdown-menu {
                border-radius: 4px;
            }
        }
    }

    &.logo {
        margin-top: 0px;
        padding-top: 0px;

        .logo-description {
            float: left;
            margin-left: 10px;
        }

        h1 {
            font-size: 20px;
            margin-bottom: 0px;
            color: $black;
            font-weight: 800;

            @include media-breakpoint-down(sm) {
                font-size: 18px;
            }
        }

        p {
            margin-bottom: 0px;
            font-size: 14px;
            color: $gray-600;
            letter-spacing: 0.7px;

            @include media-breakpoint-down(sm) {
                font-size: 10px;
            }
        }

        a {
            display: block;
            padding: 10px 0;

            &:hover {
                text-decoration: none;

                h1 {
                    color: $primary;
                }
            }
        }

        img {
            &.logo {
                max-width: 410px;
                transition: 0.2s;
                float: left;
                max-height: 50px;
                width: auto;

                &.login {
                    float: none;
                }

                @include media-breakpoint-down(sm) {
                    padding-top: 8px;
                    padding-bottom: 8px;
                    max-width: 70px;
                }
            }
        }
    }

    &.user-menu {
        margin-top: 0px;
        padding-top: 0px;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            float: right;

            li {
                float: left;

                &.my-profile {
                    span {
                        &+span {
                            font-size: 10px;
                            display: block;
                            color: $gray-600;
                            padding-left: 20px;
                            text-transform: uppercase;
                            font-weight: 400;
                            display: block;
                        }
                    }
                }

                a {
                    color: $gray-600;
                    font-size: 16px;
                    display: block;
                    text-decoration: none;
                    border-right: 1px solid $gray-200;
                    padding: 22px 10px;

                    svg {
                        font-size: 15px;
                        color: $gray-500;
                    }

                    &:hover {
                        color: $primary;
                    }
                }
            }
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &.main-menu {
        @include media-breakpoint-down(sm) {
            display: none;
        }

        ul {
            display: flex;
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                float: left;

                a {
                    color: $black;
                    transition: 0.1s;
                    font-size: 16px;
                    text-decoration: none;
                    display: block;
                    text-align: left;
                    font-weight: 400;
                    padding: 23px 0;
                }

                &.active,
                &:hover {
                    a {
                        color: $primary;

                        cursor: pointer;
                        transition: 0.3s;


                        svg {
                            margin-right: 5px;
                        }
                    }
                }
            }
        }

        &.menu {
            transition: 0.3s;
            position: relative;
            opacity: 1;

            li {
                position: relative;

                a {
                    border-top: 5px solid transparent;
                    text-align: center;
                    // margin: 0 13px;
                    cursor: pointer;

                    span {
                        max-width: 200px;
                    }

                    svg {
                        margin: 0 5px;

                        &.fa-sort-down {
                            position: relative;
                            transform: translateX(0px) translateY(-2px);
                        }
                    }

                    &:hover {
                        border-top-color: $primary;
                    }
                }

                & + li{
                    a{
                        margin-left: 10px;
                    }
                }

                &.parent {
                    &:hover {
                        .sub-menu {
                            opacity: 1;
                            transition: 0.3s;
                            visibility: visible;
                            top: 100%;
                        }
                    }
                }

                &:first-child {
                    a {
                        margin-left: 0px;
                    }
                }

                &:last-child {
                    &.parent {
                        .sub-menu {
                            right: 0;
                        }
                    }
                }

                .sub-menu {
                    position: absolute;
                    display: initial;
                    background: $white;
                    min-width: 240px;
                    opacity: 0;
                    transition: 0.3s;
                    visibility: hidden;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    z-index: 2;
                    box-shadow: 0 0 5px rgba(0, 0, 0, 0.51);

                    li {
                        float: none;
                        padding-left: 10px;
                        padding-right: 10px;

                        a {
                            background-color: none;
                            font-size: 14px;
                            justify-content: left;
                            text-align: left;
                            padding: 7px 15px;
                            border-top: 0px;
                            color: $black;
                            margin: 0;

                            &:hover {
                                color: $primary;
                            }
                        }

                        &+li {
                            border: none;
                        }
                    }
                }
            }
        }
    }

    &.page-title {
        h2 {
            font-size: 30px;
            color: $white;
            font-weight: 700;
        }

        .breadcrumb {
            background-color: transparent;
            margin-bottom: 0px;
            padding: 0px;

            li {
                color: $white;

                a {
                    color: $white;
                }

                &.active {
                    svg {
                        margin-right: 10px;
                    }
                }

                &+li {
                    margin-left: 10px;
                }
            }
        }
    }

    &.support {
        .support-icon {
            float: left;
            i,
            svg {
                width: 75px;
                height: 75px;
                font-size: 75px;
            }
        }

        .support-content {
            margin-left: 95px;

            span {
                a {
                    color: $black;
                }
            }
        }
    }

    &.socials {
        text-align: right;
        padding-top: 7px;
        padding-bottom: 0px;

        a {
            display: inline-flex;
            background-color: $primary;
            width: 36px;
            height: 36px;
            border-radius: 36px;
            font-size: 17px;
            color: $white;
            align-items: center;
            justify-content: center;
            margin: 0 3px;

            &.facebook {
                background-color: #3b5998;
            }

            &.twitter {
                background-color: #1da1f2;
            }

            &.instagram {
                background-color: #9F348F;
            }

            &.you-tube {
                background-color: #cd201f;
            }
        }
    }

    &.useful-links {
        ul {
            li {
                a {
                    color: $black;

                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
    }

    &.notice-ticker {
        margin-top: 0px;
        margin-bottom: 0px;
        padding-top: 0px;
        padding-bottom: 0px;

        h3 {
            float: left;
            font-size: 16px;
            background-color: $primary;
            color: $white;
            padding: 7px 15px;
            margin-bottom: 0px;
            font-family: $font-family-sans-serif;
            text-transform: uppercase;
            width: 90px;
            text-align: center;
        }

        a {
            &:hover {
                text-decoration: none;
            }
        }

        .notice-ticker-list {
            margin-left: 90px;
            padding-top: 5px;

            ul {
                margin: 0;
                padding: 0;
                overflow: hidden;
                white-space: nowrap;

                li {
                    list-style: none;
                    display: inline;
                    margin-left: 10px;
                    color: $gray-800;
                }
            }
        }
    }

    &.toggle-block {
        text-align: right;
        margin-left: 0px;
        padding-left: 0px;

        .toggle-menu {

            display: block;
            float: left;

            svg {
                width: 100%;
                height: auto;
            }

        }
    }

    &.mobile-menu {
        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                a {
                    font-size: 20px;
                    display: block;
                    text-align: center;
                    padding: 0 10px;
                    color: $gray-800;
                    font-weight: 400;

                    &:hover,
                    &:focus {
                        color: $primary;
                    }

                    svg {
                        display: none;
                    }
                }

                ul {
                    li {
                        a {
                            font-size: 14px;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }

    &.copyright {
        margin-bottom: 0px;
        padding-bottom: 0px;
    }

    &.developed-by {
        @include media-breakpoint-down(sm) {
            margin-top: 0px;
            padding-top: 0px;
        }
    }

    &.user-panel{
        padding: 24px 0px;

        @include media-breakpoint-down(sm) {
            .row{
                .col-md-4{
                    & + .col-md-4 {
                        padding-top: 10px;
                    }
                }
            }
        }
        a {
            color: $white;
            display: block;


            .inner-icon {
                float: left;
                width: 50px;
                height: 50px;
                margin-right: 10px;
                font-size: 30px;

            }

            .inner-text {
                margin-left: 35px;

                h3 {
                    margin-bottom: 0px;
                    font-size: 17px;
                    font-weight: bold;
                }

                p {
                    margin-bottom: 0px;
                    font-size: 9px;
                    color: rgba(255, 255, 255, 0.51);
                    ;
                }
            }

            &:hover {
                text-decoration: none;
            }
        }
    }

    &.discover-edu{
        padding: 24px 0px 23px 32px;
        background-color: darken($primary, $amount: 10);
        color: $white;

        h3 {
            a {
                color: $white;
                text-decoration: none;
                font-size: 30px;
                font-weight: bolder;
            }

            span {
                margin-left: 35px;
            }
        }
    }

    &.discover-menu{
        background-color: $white;
        li {
            a {
                color: $black;
            }
        }
    }

    &.admission-steps{
        background-color: $white;
        padding: 35px;
        position: relative;
        .step-digit {
            position: absolute;
            bottom: 0px;
            right: 1px;
            z-index: 1;
            font-size: 130px;
            font-weight: 700;
            color: $gray-100;
            line-height: 1;
        }
    }

    &.events {
        ul {
            margin: 0;
            padding: 0;
            li {
                display: table;
                width: 100%;
                margin-bottom: 10px;
                background-color: #fff;

                .date {
                    display: table-cell;
                    vertical-align: middle;
                    text-align: center;
                    width: 60px;
                    background-color: $blue;
                    padding-top: 9px;
                    padding-bottom: 9px;
                    color: $white;

                    span {
                        display: block;
                        font-weight: bold;
                        text-transform: uppercase;
                        font-size: 11px;
                        line-height: 21px;

                        &:last-child {
                            display: block;
                            font-weight: bold;
                            font-size: 16px;
                            line-height: 18px;
                        }
                    }
                }

                .info {
                    display: table-cell;
                    vertical-align: middle;
                    padding: 17px;

                    a {
                        color: $black;
                        font-weight: 800;
                    }
                }
            }
        }
    }

    &.testimonials {
        .testimonial {
            &+.testimonial {
                border-top: 1px solid $gray-300;
                padding-top: 5px;
                margin-top: 5px;
            }

            .badge {
                font-size: 10px;
                background-color: $primary;
                color: $white;
            }

            .testimonial-image {
                width: 80px;
                height: 80px;
                float: left;
                border-radius: 50%;

                img {
                    width: 100%;

                }
            }

            .testimonial-content {
                padding-left: 95px;

                h5,
                h6,
                p {
                    margin-bottom: 2px;
                }

                h5 {
                    font-size: 20px;
                    font-weight: 800;
                }

                h6 {
                    font-weight: 800;
                    font-size: 16px;
                }

                p {
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
    }

    &.why-choose-menu{
        ul{
            margin: 0;
            padding: 0;
             li {
                 list-style: none;
                 a {
                     display: block;
                     padding-left: 5px;
                    color: $black;
                     &:hover {
                         padding-left: 10px;
                         color: $primary;
                     }
                 }

                 &+li {
                     border-top: 1px solid $gray-400;
                     padding-top: 9px;
                     margin-top: 9px;
                 }
             }
        }
    }

    &.users{
        .card{
            margin-bottom: 30px;
        }
    }

    &.govt-promotion{
        a{
            display: inline-block;
            background-color: $gray-200;
            margin: 5px;
            padding: 5px;
            float: left;
            img{
                width: auto;
                height: auto;
                max-width: 100px;
                max-height: 50px;
                float: left;
            }
            .govt-promotion-text{
                margin-left: 55px;
                h4{
                    margin-bottom: 0px;
                    color: $gray-800;
                    font-size: 12px;
                    line-height: 15px;
                    font-weight: bold;
                }
                p{
                    margin-bottom: 0px;
                    color: $gray-800;
                    font-size: 10px;
                    line-height: 12px;
                }
            }
        }
    }

    &.image-upload {
        .image-upload-inner {
            width: 100%;
            max-height: 150px;
            margin: 0 auto 15px;
            overflow: hidden;
            border-radius: 4px;
            text-align: center;
            position: relative;
            height: 150px;
            background: $gray-300;

            a {
                display: block;
                height: 150px;
                padding: 5px;
                align-items: center;
                display: flex;

                img {
                    max-width: 100%;
                    max-height: 100%;
                    width: auto;
                    margin: 0 auto;
                    display: table;
                }
            }

            .image-icon {
                font-size: 100px;
                color: $gray-400;
                position: absolute;
                top: 25px;
                left: 0;
                right: 0;
                bottom: 0;
                margin: 0 auto;
            }

            .image-remove {
                position: absolute;
                z-index: 1;
                left: 50%;
                margin-left: -42.5px;
                top: 5px;
                width: 85px;
                height: 35px;

                .btn {
                    background: $danger;
                    width: 85px;
                    height: 35px;
                    border-radius: 5px;
                    color: $white;
                    position: absolute;
                    left: 0;
                    top: 5px;
                    border: 0;
                    padding: 0;
                    font-size: 17px;
                }

                &:hover {
                    cursor: pointer;

                    .btn {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &.profile-menu{
        ul{
            margin: 0;
            padding: 0;
            list-style: none;
            li{
                a{
                    display: block;
                    color: $black;
                    font-weight: 900;
                    font-size: 20px;
                    padding: 5px 0;
                    text-decoration: none;
                    &.active,
                    &:hover{
                        color: $blue;
                    }
                }
                ul{
                    padding-left: 15px;
                }
            }
        }
    }
}

#heading {
    background-color: $primary;
    background-size: cover;
    position: relative;

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0px;
        background-color: rgba(0, 0, 0, 0.5);
        content: "";
    }
}

header,
#navigation {
    border-bottom: 1px solid $gray-200;
}

footer {
    #bottom {
        background-color: $gray-300;
    }
}

.add-items{
    .custom-file-input{
        &:hover{
            ~.custom-file-label{
                 opacity: 0.7;
                 cursor: pointer;
            }
        }
        &[disabled],
        &:disabled{
            ~.custom-file-label {
                background-color: $gray-500;
                svg{
                    width: 20px;
                    color: $white;
                    font-size: 20px;
                    margin: 0;
                }
            }
        }
    }
    .custom-file-label{
        background-color: $primary;
        color: $white;
        font-weight: 400;
        font-family: 'Montserrat', sans-serif;
        padding-right: 40px;
        border: none;
        svg{
            position: absolute;
            right: 15px;
            top: 10px;
        }
        &:after{
            display: none;
        }
    }
}

.files{
    margin: 0 -5px;
    .image{
        position: relative;
        margin: 5px;
        padding: 5px;
        background: $gray-200;
        border-radius: 4px;
        img{
            width: 100%;
            height: auto;
            border-radius: 3px;
        }
        a{
            display: block;
            width: 64px;
            max-height: 64px;
            overflow: hidden;
            float: left;
            font-size: 16px;
            &:hover {
                opacity: 0.7;
            }
        }
    }
    .btn{
        &.btn-caption{
            font-size: 12px;
            font-weight: 400;
            padding: 3px 7px;
        }
        &.remove-attach,
        &.remove-file {
            padding: 5px 7px;
            margin: 0;
            font-size: 15px;
            z-index: 1;
            svg{
                float: left;
            }
        }
    }
}